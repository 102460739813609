import { inject } from "@angular/core";
import { FraudService } from "./api/services/fraud.services";
import { catchError } from "rxjs/operators";
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router";

const handlerResolverError = (router: Router) => async (error: any) => {
    await router.navigateByUrl('/something-went-wrong');
    throw error;
};

export const searchTransactionsForAdmin = () => {
    const router: Router = inject(Router);
    return inject(FraudService).searchTransactionsForAdmin().pipe(catchError(handlerResolverError(router)));
}

export const searchReportsForAdmin = () => {
    const router: Router = inject(Router);
    return inject(FraudService).searchReportsForAdmin().pipe(catchError(handlerResolverError(router)));
}

export const getUserByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getUserByUuid(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getDashboards = () => {
    const router: Router = inject(Router);
    return inject(FraudService).getDashboards().pipe(catchError(handlerResolverError(router)));
}

export const getTransactionByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getTransactionUuidUser(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getReportsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getReports(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getOperationsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getOperations(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getRentalsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getRentals(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getReportsStatsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getReportsStats(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getTransactionStatsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getTransactionsStats(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getReferralsStatsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getReferralsStats(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getSubscriptionStatsByUuid: ResolveFn<any> = ({ params: { uuid } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getSubscriptionStats(uuid).pipe(catchError(handlerResolverError(router)));
}

export const getDailyGraphDashboard = (from: string, to: string): ResolveFn<any> => () => {
    const router: Router = inject(Router);
    return inject(FraudService).getDailyGraphDashboard(from, to).pipe(catchError(handlerResolverError(router)));
}
export const getMonthDashboard = (from: string, to: string): ResolveFn<any> => () => {
    const router: Router = inject(Router);
    return inject(FraudService).getMonthDashboard(from, to).pipe(catchError(handlerResolverError(router)));
}

export const getStopWalletById: ResolveFn<any> = ({ params: { id } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getStopWalletById(id).pipe(catchError(handlerResolverError(router)));
}

export const getStopWalletTransactionById: ResolveFn<any> = ({ params: { id } }: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    return inject(FraudService).getStopWalletTransactionById(id).pipe(catchError(handlerResolverError(router)));
}