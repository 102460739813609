export const svgIcons: string[] = [
    'shield',
    'tooltip-icon',
    'circle-usdt',
    'users',
    'download',
    'logout',
    'edit',
    'wallet',
    'copy-clipboard',
    'back',
    'choose',
    'wallet_type_cold',
    'wallet_type_exchange'
];
