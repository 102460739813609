import { inject, provideAppInitializer } from "@angular/core";
import { AuthService } from "./api/services/auth.service";
import { Router } from "@angular/router";
import { catchError, map, Observable, tap } from "rxjs";
import { ProfileActions, ProfileStoreState } from "./core/store/profile";
import { Store } from "@ngrx/store";


export const appConfigInit = provideAppInitializer(() => {
    const initializerFn = ((router: Router, authService: AuthService, profileStore: Store<{
        profile: ProfileStoreState
    }>) => {
        return (): Observable<boolean> | Promise<boolean> => {
            const token: string | null = localStorage.getItem('token');
            const userInfo: string | null = localStorage.getItem('userInfo');

            if (!token) {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                return router.navigate(['login']);
            }

            return authService.checkValidSession().pipe(
                tap(() => {
                    if (userInfo && JSON.parse(userInfo)) {
                        const parsedUserInfo = JSON.parse(userInfo)
                        profileStore.dispatch(ProfileActions.setProfileData({ payload: parsedUserInfo }));
                    }
                }),
                map(Boolean),
                catchError(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userInfo');
                    return router.navigate(['login']);
                }));
        }
    })(inject(Router), inject(AuthService), inject(Store));
    return initializerFn();
});
