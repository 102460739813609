import { Routes } from '@angular/router';
import {
  getDailyGraphDashboard,
  getDashboards,
  getMonthDashboard,
  getOperationsByUuid,
  getReferralsStatsByUuid,
  getRentalsByUuid,
  getReportsByUuid,
  getReportsStatsByUuid,
  getStopWalletById,
  getStopWalletTransactionById,
  getSubscriptionStatsByUuid,
  getTransactionByUuid,
  getTransactionStatsByUuid,
  getUserByUuid,
  searchReportsForAdmin,
  searchTransactionsForAdmin
} from "./resolvers";
import { userGuard } from "./partials/guards/user.guard";
import moment from "moment";

export const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        loadComponent: () => import('./layout/layout.component').then(c => c.LayoutComponent),
        children: [
            {
                path: 'blocking',
                canActivate: [userGuard(['SUPER_USER', 'WALLET_STOP_MANAGER', 'GENERAL_MANAGER'])],
                children: [
                    {
                        path: 'monitoring-list',
                        loadComponent: () => import('./pages/blocking/blocking-monitoring-list/blocking-monitoring-list.component').then(c => c.BlockingMonitoringListComponent)
                    }
                ],
            },
            {
                path: 'blocking/:id',
                resolve: {
                    stopWalletData: getStopWalletById,
                    stopWalletTransactionData: getStopWalletTransactionById
                },
                loadComponent: () => import('./pages/blocking/blocking-case/blocking-case.component').then(c => c.BlockingCaseComponent)
            },
            {
                path: 'transactions',
                resolve: {
                    data: searchTransactionsForAdmin
                },
                loadComponent: () => import('./pages/transactions/transactions.component').then(c => c.TransactionsComponent)
            },
            {
                path: 'reports',
                resolve: {
                    data: searchReportsForAdmin
                },
                loadComponent: () => import('./pages/reports/reports.component').then(c => c.ReportsComponent)
            },
            {
                path: 'merchants',
                resolve: {
                    dashboardStats: getDashboards
                },
                loadComponent: () => import('./pages/merchants/merchant-list/merchant-list.component').then(c => c.MerchantListComponent)
            },
            {
                path: 'merchant/:uuid',
                resolve: {
                    userEntity: getUserByUuid,
                    userReports: getReportsByUuid,
                    userOperations: getOperationsByUuid,
                    userTransactions: getTransactionByUuid,
                    userRentals: getRentalsByUuid,
                    userReportsStats: getReportsStatsByUuid,
                    userTransactionsStats: getTransactionStatsByUuid,
                    userReferralsStats: getReferralsStatsByUuid,
                    userSubscriptionStats: getSubscriptionStatsByUuid
                },
                loadComponent: () => import('./pages/merchants/merchant/merchant.component').then(c => c.MerchantComponent)
            },
            {
                path: 'monitoring',
                resolve: {
                    initFrom: () => moment().subtract(7, 'days').format(),
                    initTo: () => moment().format(),
                    initDailyGraphDashboard: getDailyGraphDashboard(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')),
                    iniMonthDashboard: getMonthDashboard(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')),
                },
                canActivate: [userGuard(['SUPER_USER'])],
                loadComponent: () => import('./pages/monitoring/monitoring.component').then(c => c.MonitoringComponent)
            },
            {
                path: '404',
                loadComponent: () => import('./pages/errors/not-found/not-found.component').then(c => c.NotFoundComponent)
            },
            {
                path: 'something-went-wrong',
                loadComponent: () => import('./pages/errors/something-went-wrong/something-went-wrong.component').then(c => c.SomethingWentWrongComponent)
            },
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('./pages/start/start.component').then(c => c.StartComponent)
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
