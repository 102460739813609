import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly _apiUrl: string = environment.apiUrl;
    private readonly _httpClient: HttpClient = inject(HttpClient);

    checkValidSession(): Observable<boolean> {
        return this._httpClient.get<boolean>(`${ this._apiUrl }api/session/valid`, {});
    }
}
