import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { ProfileStoreState } from "./core/store/profile";
import { Store } from "@ngrx/store";
import { ThemeService } from "./partials/services/theme.service";
import { svgIcons } from './svg-icons';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html'
})
export class AppComponent {
    private readonly _profileStore: Store<{ profile: ProfileStoreState }> = inject<Store<{
        profile: ProfileStoreState
    }>>(Store);
    private readonly _matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
    private readonly _domSanitizer: DomSanitizer = inject(DomSanitizer);
    private readonly _themeService: ThemeService = inject(ThemeService);

    constructor() {
        this._registerIcons(svgIcons);
        this._themeService.init();
    }

    private _registerIcons(icons: Array<string>): void {
        icons.forEach((icon: string) => {
            this._matIconRegistry.addSvgIcon(
                icon,
                this._domSanitizer.bypassSecurityTrustResourceUrl(
                    `assets/icons/custom/${ icon }.svg`
                )
            );
        });
    }
}
